import React from 'react';

export const COOKIES_AGREEMENT = 'earthprizecookies';
export const COOKIES_LIFETIME_IN_DAYS = 90;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const WEBSITE_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const STRONG_PASSWORD_REGEX = new RegExp(
  '(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
);
export const video1 = 'https://storage.googleapis.com/theearthprize/video1.mp4';
export const video2 = 'https://storage.googleapis.com/theearthprize/video2.mp4';
export const video3 = 'https://storage.googleapis.com/theearthprize/video3.mp4';
export const video4 = 'https://storage.googleapis.com/theearthprize/video4.mp4';
export const video5 = 'https://storage.googleapis.com/theearthprize/video5.mp4';
export const scrollSliderSpeed = 500;
export const scrollSliderKey = '40EE6E60-758340D3-9DD86CB7-7E0EFC1C';

export enum UserRole {
  Student = 'student',
  Teacher = 'teacher',
  Mentor = 'mentor',
  Parent = 'parent',
}

export const GENDER = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Other', value: 'other' },
  { label: 'Prefer not to say', value: 'prefer_not_to_say' },
];

export const HOW_HEART_ABOUT_US = [
  { label: 'On Instagram', value: 'Instagram' },
  { label: 'On Facebook', value: 'Facebook' },
  { label: 'On Twitter', value: 'Twitter' },
  { label: 'On LinkedIn', value: 'LinkedIn' },
  { label: 'On the internet', value: 'Internet' },
  {
    label: 'I was contacted directly by The Earth Prize team',
    value: 'I was contacted directly by The Earth Prize team',
  },
  { label: 'Through a friend', value: 'Through a friend' },
];
export const HOW_HEART_ABOUT_US_STUDENT = [
  { label: 'Through my school/teacher', value: 'Through my school/teacher' },
  ...HOW_HEART_ABOUT_US,
];

export const HOW_HEART_ABOUT_US_PARENT = [
  {
    label: "Through my child's school/teacher",
    value: "Through my child's school/teacher",
  },
  ...HOW_HEART_ABOUT_US,
];
export const HOW_HEART_ABOUT_US_TEACHER = [
  { label: 'Through my school/student', value: 'Through my school/student' },
  ...HOW_HEART_ABOUT_US,
];
export const STATES = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
];

// eslint-disable-next-line
export const REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LEADERSHIP = [
  {
    name: 'Anett Thomson, Head of Strategy & Operations',
    photo: '/images/anett.jpg',
    text: "Anett Thomson is a dynamic self-starter driven by an entrepreneurial spirit. Known for taking ownership and thriving in fast-moving environments, Anett brings valuable experience from both the public and private sectors, excelling in strategy, operations, and project management. Passionate about sustainability and entrepreneurship, she is committed to making a genuine impact and addressing global challenges. Originally from Estonia, Anett's international journey has seen her studying and working in diverse settings, including Luxembourg, the United Kingdom, Singapore, Hong Kong, and Switzerland.",
  },
  {
    name: 'Loukia Psaraki, Head of Communications',
    photo: '/images/loukia.png',
    text: 'Loukia Psaraki is a communications professional with experience in the civil society and media ecosystems. She has effectively communicated social impact for programs relating to health, sustainability, and education, while working with a philanthropic foundation in Athens, Greece. She has also worked with Amnesty International and a political and news content startup. Loukia holds an MSc in Strategic Communications from the London School of Economics and Political Science (LSE) and a BSc in International Politics from the University of Surrey. Loukia has lived and worked in Germany, the Netherlands, the United Kingdom, and Greece.',
  },
  {
    name: 'Margherita Pinardi, Project Management Associate',
    photo: '/images/margherita.png',
    text: "Margherita Pinardi has project management experience in environmental protection, both at the national and international levels. At the United Nations Environment Programme, she contributed to projects relating to the prevention and sound management of plastics and microplastics in the oceans. Margherita graduated from the University of Milan with a master's degree in international relations with a specialisation in international environmental law. She is really passionate about whales and the ocean environment and has studied and worked in Norway, Switzerland, and Italy.",
  },
  {
    name: 'Susie Klindt Noe, Volunteer Community Manager',
    photo: '/images/susie.jpeg',
    text: 'Susie Klindt Noe has been a dedicated and international project manager for more than a decade. She is passionate about sustainability and working with community building which has brought her to dedicate herself in various non-profit organizations over the past 15 years, as a volunteer and a board member. Susie holds a Masters Degree in Economics and Business Administration from Denmark and the US. She now resides in Lisbon, Portugal with her family but has previously lived and worked in many different countries across three continents.',
  },
  {
    name: 'Charlotte Tucker, Media & PR Manager',
    photo: '/images/charlotte.jpeg',
    text: 'Charlotte is a Media and PR Manager at The Earth Foundation. Formerly the Editor-in-Chief of EU-Startups, Europe’s largest startup publication, she now secures media coverage for the foundation’s exciting work in outlets like Earth.org, Positive.News, One Earth, and Maddyness. Before taking on diverse media roles, including within EU-funded innovation projects and a creative agency, Charlotte obtained a First Class (Hons) Bachelor’s in Linguistics from The University of Southampton and a Master’s (with Merit) in Contemporary European Politics from The University of Bath.Charlotte splits her time between the UK and Spain, where she lived for over 11 years, and her love for natural environments and the ocean has also led her to become a qualified paddleboard instructor.',
  },
];

export const AMBASSADORS_MEMBERS = [
  {
    id: 'suzy-amis-cameron',
    name: 'Suzy Amis Cameron',
    photo: '/images/suzy.jpeg',
    text: 'Environmental advocate, business pioneer and mom of five, Suzy Amis Cameron is committed to caring for the Earth, with a focus on plant-based food to address climate change. She is the author of The OMD Plan and founder of the OMD Movement, an advocacy group dedicated to expanding access to climate-friendly food. Expanding her knowledge in plant-based science, Suzy earned her Plant-Based Nutrition Certificate through eCornell in 2021. In 2005, she founded MUSE Global School, the first ECE-12th grade school in the U.S. to be solar-powered, zero waste, with a plant-based lunch program, which launched MUSE Global in 2018 to franchise the school worldwide. Suzy and her husband, James Cameron founded MUSE Virtual, a holistic K-12 online learning platform providing interactive, innovative education to students across the world. She is co-founder of Verdient Foods, Cameron Family Farms, Food Forest Organics and Plant Power Task Force. In 2009, she launched Red Carpet Green Dress, a global change-making organization in partnership with The Oscars that is bringing sustainability to the forefront of conversation and action within the global apparel industry. Suzy is an Executive Producer of The Game Changers, MILKED and was featured in 25 films, including The Usual Suspects and Titanic.',
  },
  {
    id: 'jonathan-baillie',
    name: 'Jonathan Baillie',
    photo: '/images/jonathan.jpeg',
    text: (
      <span>
        Jonathan Baillie is CEO of On The EDGE Conservation, a media and
        technology company for nature, and President of the board NATURAL STATE,
        an organization that leverages technology to design, implement and
        monitor large-scale nature restoration initiatives. Jonathan was
        previously Executive Vice President and Chief Scientist at the National
        Geographic Society where he oversaw grants, impact initiatives, National
        Geographic Labs, explorer programs, and the international team,
        initiating several ground-breaking collaborations across regions and
        sectors to conserve and monitor the natural world.
        <br />
        <br />
        Before National Geographic Society he was Director of Conservation
        Programmes at the Zoological Society of London where he built and
        oversaw a portfolio of conservation projects in over 50 countries, and
        gained a reputation for developing innovative, cross-sectoral
        collaborations to support conservation at scale. <br />
        <br />
        He holds an Honours BA in geography from Queen’s University in Canada, a
        Master’s Degree in conservation biology from Yale University, and a
        Ph.D. in biology from Silwood Park, Imperial College London. Jonathan
        has been a visiting professor at the University of Oxford since 2009.{' '}
        <br />
        <br />
        Image: ©Rolex/Audoin Desforges
      </span>
    ),
  },
  {
    id: 'beatrice-fihn',
    name: 'Beatrice Fihn',
    photo: '/images/beatrice.jpeg',
    text: "Beatrice Fihn is the Executive Director of the International Campaign to Abolish Nuclear Weapons (ICAN), and accepted the 2017 Nobel Peace Prize on behalf of the campaign. Ms. Fihn has led the campaign since 2014 and has worked to mobilize civil society throughout the development of the United Nation's Treaty on the Prohibition of Nuclear Weapons. She frequently lectures, delivers high-level speeches, and provides media comments about issues like weapons law, humanitarian law, civil society engagement in diplomacy and multilateral institutions, as well as the gender perspective on disarmament work. Ms. Fihn holds a master’s degree in law from the University College London and a bachelor’s degree in International Relations from Stockholm University.",
  },
  {
    id: 'kasha-sequoia-slavner',
    name: 'Kasha Sequoia Slavner',
    photo: '/images/kasha.webp',
    text: (
      <span>
        Kasha Sequoia Slavner is the founder of The Global Sunrise Project and
        Director of the multi-award-winning documentary, The Sunrise
        Storyteller. She has been a filmmaker, photographer, entrepreneur, and
        social justice advocate for over a decade, as well as a writer, public
        speaker and contributor to several publications, including National
        Geographic Learning. Kasha is an 13x UN Youth Delegate, one the UN’s
        Office for Disarmament Affairs 25 #leaders4tommorow and was selected as
        the first winner of the Kim Phuc Youth Peace Prize. In 2019, she was the
        recipient of the Diana Award and recently was selected as one of the
        Wonder Grantees for Sustainability by the Shawn Mendes Foundation. Her
        current feature documentary in production is called 1.5 Degrees of
        Peace. The{' '}
        <a href="https://vimeo.com/632022029" target="_blank" rel="noreferrer">
          trailer
        </a>{' '}
        for the film has already won several prestigious awards.
      </span>
    ),
  },
  {
    id: 'navya-naveli-nanda',
    name: 'Navya Naveli Nanda',
    photo: '/images/navya.jpeg',
    text: "Navya Naveli Nanda is an entrepreneur and women's rights advocate. She is the co-founder of Aara Health, a women's healthcare platform in India focusing on providing medically verified content around women’s health & scientifically-backed products for preventative care. She is also the founder of Project Naveli a non-profit initiative supporting gender equality in India, especially focusing on the fields of education, healthcare & financial independence. She works closely with the United Nations and UN Women entities, as one of the youngest UN Women Allies. Navya graduated from Fordham University in New York with a major in digital technology and UX design.",
  },
  {
    id: 'gitanjali-rao',
    name: 'Gitanjali Rao',
    photo: '/images/rao.jpeg',
    text: 'Gitanjali Rao was recognized as America\'s Top Young Scientist and received an EPA Presidential award for inventing her device "Tethys"—an early lead detection tool. Gitanjali is also the inventor of “Epione”—a device for early diagnosis of prescription opioid addiction using genetic engineering, and "Kindly"—an anti-cyberbullying service using AI and Natural Language processing. She was honored as Forbes “30 Under 30 in Science” in 2019 and TIME’s “Top Young Innovator” and "TIME Kid of the Year" for her innovations and STEM workshops she conducts globally. Gitanjali is also the author of the book "Young Innovator\'s Guide to STEM", which guides students, educators, or teachers with a prescribed 5 step innovation process. She was honored as one of America\'s Top Youth Volunteers by Prudential in 2021 and was appointed as a UNICEF Youth Advocate for using science for solving social problems such as cyber-bullying and developing solutions for environmental protection. She recently received a grant as a National Geographic Young Explorer to expand her workshops and self-sustain them beyond her.',
  },
  {
    id: 'okezue-bell',
    name: 'Okezue Bell',
    photo: '/images/okezue.jpeg',
    text: "Okezue Bell is a Nigerian-American high school senior, activist, and social entrepreneur. He is the founder and director of Fidutam, a SIM card-based application that provides microloans to low-infrastructure and unbanked communities. Okezue invented the WeArm, a low-cost and 3D-printed humanlike prosthetic arm for below-the-elbow amputees. Bell has conducted research at the intersection of engineering and the life sciences at Harvard Medical School, the MIT Media Lab, and the Boston Children's Hospital. He has devoted over 1,000 volunteer hours to impact over 65,000 kids across 80 countries. In addition to his youth mentorships, Okezue works with global sustainability brands to help scale their work to Generation Z demographics. He has worked with H&M, Leonardo DiCaprio, Evolvetogether, and more.",
  },
  {
    id: 'louie-schwartzberg',
    name: 'Louie Schwartzberg',
    photo: '/images/louie.jpeg',
    text: (
      <span>
        Louie Schwartzberg is an award-winning cinematographer, director, and
        producer who has spent his notable career providing breathtaking imagery
        using his time-lapse, high-speed, and macro cinematography techniques.
        <br />
        <br />
        Louie’s theatrical releases include Fantastic Fungi (narrated by Brie
        Larson); the 3D IMAX film, Mysteries of the Unseen World (National
        Geographic/narrated by Forest Whitaker); the theatrical feature, Wings
        of Life (Disneynature/narrated by Meryl Streep); and America’s Heart and
        Soul (Walt Disney Studios). His ride-film, Soarin’ Around the World, is
        shown for one of Disney Theme Parks’ most popular attractions worldwide.
        <br />
        <br />
        Schwartzberg’s Visual Healing is an offering immersive nature films that
        seamlessly bring nature and music together to deliver positive physical,
        emotional, and psychological benefits in healthcare and hospitality.
        Schwartzberg is a visual artist who breaks barriers, connects with
        audiences, and tells stories that celebrate life. For Schwartzberg, the
        greatest satisfaction is creating works that inspire audiences to
        celebrate Life and protect what we love.
      </span>
    ),
  },
  {
    id: 'natalie-schmitt',
    name: 'Natalie Schmitt',
    text: 'Natalie Schmitt is an empathetic ecologist, conservation geneticist, and documentary presenter, deeply passionate about protecting biodiversity using hard science, public education, and community empowerment. Studying elusive, endangered species, like snow leopard and Antarctic whales, she has empowered grass-roots conservation, and enabled policy education and management improvement at various scales, including that of the International Whaling Commission. Natalie is developing a portable leading-edge technology to detect DNA from biological samples in real time, at low cost and easy-to-use. She leads an extremely skilled, synergic team of geneticists, conservationists, communicators, business partners, and institutions to develop this technology. WildTechDNA will categorically revolutionize the way how customs, law-enforcement, field monitoring practices, citizen science, and locally based conservation are conducted around the world.',
    photo: '/images/natalie.jpg',
  },
];

export const JUDGES = [
  {
    name: 'Karen Wilson',
    position: 'Chair of the Panel',
    photo: '/images/karen-wilson.jpg',
    paragraphs: [
      'Karen Wilson is the Founder of GV Partners and an expert on innovation, entrepreneurship, finance, sustainability and impact measurement, working with private sector firms, international organizations, foundations and academia. She is an Associate Fellow at Said Business School at Oxford University and a Senior Advisor on Sustainability and ESG at the Institute for Strategy at the Stockholm School of Economics in Riga, where she is also a Visiting Lecturer. She has a Bachelors of Science from Carnegie Mellon University and an MBA from Harvard Business School.',
    ],
  },
  {
    name: 'Steven Monfort',
    position: 'Member of the Panel',
    photo: '/images/steven.jpg',
    paragraphs: [
      "Steve Monfort is the Executive Director of the University of California's Natural Reserve System. He is also the former director of the Smithsonian's National Zoo and Conservation Biology Institute and currently serves as a senior advisor to the Smithsonian's Under Secretary for Science and Research. As a pioneer in the field of Conservation Biology, Dr. Monfort is a subject matter expert in Zoo Biology, Animal Health, Reproductive Biology, and Behavioral Ecology. Over the course of his 33-year career at the Smithsonian, he has led scientists on the cutting-edge of saving species, from developing non-invasive endocrine monitoring techniques to reintroducing the previously-extinct Scimitar-horned Oryx back into the wild. More recently, he has led the Smithsonian in adopting a “solutions-based mindset,” focusing on what is working in conservation and how we can do more of it.",
    ],
  },
  {
    name: 'Kisum Chan',
    position: 'Member of the Panel',
    photo: '/images/kisum.jpg',
    paragraphs: [
      'Kisum is a Forbes 30 Under 30 social entrepreneur and co-founder of Rice Inc, an agtech startup that develops tech-based solutions to fight the production waste occurring in the rice industry.',
      'Rice Inc was founded in 2018 during Kisum’s time at the University College London (UCL). Since then Rice Inc has been recognised as a leading social enterprise that’s changing the world by winning several accolades. In particular Rice Inc has won the prestigious $1 million Hult Prize in 2018 and has been featured in leading news outlets including the BBC, Business Insider and Forbes.',
    ],
  },
  {
    name: 'Esther Ndeti',
    position: 'Member of the Panel',
    photo: '/images/esther.png',
    paragraphs: [
      'Esther Ndeti is Investment Principal at Uncap.',
      'With over a decade of experience, she has built a deep understanding of investment strategy in the venture funding space as well as tactical experience in early-stage financing. Her areas of focus have included strategic analysis, due diligence assessment, capital-raising advisory and portfolio management. She is also an advocate for women in business and recently founded ‘Preneur Today’, an initiative that spotlights the work of female entrepreneurs in Africa.',
      'In her previous role, Esther was Executive Director of the East African Private Equity and Venture Capital Association (EAVCA), where she worked at the intersection of finance and private sector development; supporting institutional investors and regulators through market analysis, strategy development, policy formulation and fundraising. Esther has also served on the Board of Directors of Victoria Business Angels (VBAN), GrowthAfrica and ASSEK.',
      'Esther received her Bachelor of Science in Mechanical Engineering from the University of Nairobi.',
    ],
  },
  {
    name: 'Federico Bellone',
    position: 'Member of the Panel',
    photo: '/images/federico.webp',
    paragraphs: [
      'Federico Bellone is an environmental scientist with a background in international development, strategic philanthropy, corporate responsibility, and partnership-building for sustainable development and systems change in Latin America, USA, and Europe. Specializing in systemic nature-based solutions to climate change, particularly in Latin America, Federico is currently dedicated to large-scale systems change for regeneration. He serves in a partnerships and development role for Conexsus, an award-winning Brazilian social enterprise providing direct training, financing and access to markets to 400+ community-based bioeconomy enterprises in Latin America, in partnership with the Inter-American Development Bank and the Green Climate Fund. Formerly the Global Lead for Food and Agriculture systems at the UNFCCC High-Level Climate Champions, Federico brings a wealth of experience from roles at Porticus, Avina Foundation and the Spanish International Development Agency (AECID).',
    ],
  },
];

export const AMBASSADORS = [
  {
    name: '',
    photo: '',
    position: '',
    text: 'The Earth Prize Mentors are university students who are passionate about sustainability and entrepreneurship and looking for an opportunity to share their knowledge. Mentors will contribute to the development of students’ innovative project proposals by offering them guidance on their ideas and answering their questions. The Earth Prize 2023 will now recognize three mentors with The Earth Prize Mentor of the Year award. The three best mentors, as voted by the competition’s Participants, will receive a $2,500 prize each.',
  },
];

export const YOUTH = [
  {
    name: 'Beyza Kaya',
    position: '2024-2025 Youth Board member',
    photo: '/images/beyza.png',
    paragraphs: [
      'Beyza is a dedicated and passionate student from Turkey, driven by her commitment to safeguard vulnerable communities, particularly in her region. Recognized as the "Science Ambassador" of Southeastern Turkey by the STEM Educators Association, she has spearheaded various impactful STEM-related community projects. Among her notable achievements is founding Healthy Companions, where she developed "Tail Tracker," an innovative device that monitors the health status of pets through advanced sensors. This work led her and her team to win the prestigious "Donald James Citizenship Award" at the Conrad Challenge, underscoring her leadership and dedication to community service. As an aspiring environmental engineer, Beyza is determined to leverage technology to address and solve global challenges, paving the way for a brighter, more sustainable future.',
    ],
  },
  {
    name: 'Dedie Mwenda',
    position: '2024-2025 Youth Board member',
    photo: '/images/dedie.png',
    paragraphs: [
      'Dedie Mwenda, known as "The Environmentalist," is Kenyan student dedicated to environmental conservation. Born in 2007, in Nairobi, he now lives in Kalro-kabete with his family. Dedie’s passion for tree planting began in primary school, inspired by his father\'s stories about Nobel laureate Wangari Maathai. In 2022, he graduated with top marks and joined The Maseno School in Kisumu and launched the "A tree per term" initiative to combat deforestation, leading to a significant reduction in deforestation rates. He serves as the deputy environmental captain and chairman of the Environment and Sustainability club at Maseno School, where they recycle plastics and support underfunded schools and orphanages. Dedie’s efforts have gained recognition, leading to features in the Sunday Nation Newspaper and participation in environmental summits. Dedie aims to become a renowned environmentalist, mentor other youths, and join the decision-making body of the United Nations Environmental Programme (UNEP) to tackle global climate challenges.',
    ],
  },
  {
    name: 'Selin Ozgursoy',
    position: '2024 Youth Board member',
    photo: '/images/selin.jpg',
    paragraphs: [
      "Selin Ozgursoy is a Turkish-American social entrepreneur and AI ethicist. She is UNICEF Turkey's Inaugural Youth Ambassador and UN’s Youngest Advisor on Business in Europe and Central Asia. Ozgursoy is the Founder & Executive Director of Equity Estrel, the biggest international youth-led initiative based in Europe democratizing access to technology. She actively serves as a Youth Advisor to industry giant corporations including Fast Company (GenZ Advisory Board), Microsoft, IBM, and Unilever. She's the youngest UNICEF Youth Foresight Fellow, Chairwoman of UN Women's AI Youth Council, a TEDx speaker, and Forbes' 2023 “Teen Trailblazer.\"",
    ],
  },
  {
    name: 'Zlata Shama',
    position: '2024 Youth Board member',
    photo: '/images/zlata.webp',
    paragraphs: [
      'Zlata Shama is a 14-year-old student at the International School of Geneva. She has always loved being involved in global issues in particular sustainability, equity and social interaction, she has shown that throughout multiple school initiatives such as student council and 9innovate project. Since June 2022, she is a Villars Institute Fellow an solution-oriented nonprofit foundation to accelerate the transition to net zero economy. Zlata is following the Villars Institute & Minerva Project: Systems Leadership Program. During the summer 2023, she was nominated to be a Youth Jury at the FIFAD film festival 2023. Outside, she is also a dancer at national level.',
    ],
  },
  {
    name: 'Dashel Myers',
    position: '2024 Youth Board member',
    photo: '/images/dashel.webp',
    paragraphs: [
      "Dashel Myers, a junior at Fieldston School in the Bronx, has co-authored a computational mathematics textbook using Mathematica. He's the Ambassador for Wolfram Research and a leading member of the National STEM Honor Society's advisory council, focusing on SEO. As an entrepreneur, he established an AI firm merging SIEM system functionalities with natural language processing. Actively involved in New York political campaigns, Dashel supports climate-centric candidates and co-created the Organizing Resource Library (ORL) with NYU to aid disenfranchised communities. He aims to study the metamathematics of macromolecule evolution in the future.",
    ],
  },
  {
    name: 'Aarna Wadhawan',
    position: '2023 - 2024 Youth Board member',
    photo: '/images/aarna.webp',
    paragraphs: [
      'Aarna Wadhawan is a 14-year-old Indian student. She is a young environmentalist and is working passionately for the causes of afforestation, air, water and soil Pollution along with girl child education. Planting over 7500 trees in India and across the world, Aarna is a 2x TEDx speaker, Josh Talks speaker and has been associated with major organisations such as WWF India, TERI, Global Resilience Partnership, Girl Up Bani among others. She is the youth ambassador of Project 100 and an awardee of the Bharat Youth Awards 2022 (India). Having worked with various NGO’s such as Princess Diya Kumari Foundation, World Environment Council, Project 100, Mobius Foundation and more, she was also an Earth Prize Scholar 2022 for her creation Ecosteria, the Solar Powered UV Lamp which aims to provide sterilization to rural areas where there is a lack of supply of electricity. She was appreciated by the Environment Minister of India for her efforts. Moving ahead, she wants to motivate and inspire young adults like herself to adopt sustainable and environmentally friendly practices to create change in mindsets around the world.',
    ],
  },
  {
    name: 'Sagarika Sriram',
    position: '2022 - 2024 Youth Board member',
    photo: '/images/sagarika.png',
    paragraphs: [
      'Sagarika Sriram is the 16 year old Founder/CEO of Kids4abetterworld, established in Dubai in 2017. She used her knowledge of Coding and Web development to create an innovative ”Kids for Kids” technology platform that helps young children to drive sustainability projects in their communities. K4Bworld conducts awareness workshops on sustainability, recycling and waste management, beach and desert clean-ups, and projects related to marine conservation. It aims to educate, motivate and activate children to conserve natural resources and biodiversity. With close to 100,000 visitors to its platform and growing media presence, Kids4aBetterWorld is also a member of UNESCO and CRI #LearningPlanet.',
    ],
  },
  {
    name: 'Lesein Mutunkei',
    position: '2022 - 2024 Youth Board member',
    photo: '/images/lesein.webp',
    paragraphs: [
      'Lesein Mutunkei is a 16 year old Kenyan high school student and an avid football player. Lesein heard about deforestation and climate change when he was 12 years old. Inspired by Prof Wangari Maathai, Lesein combined his love for football and nature by committing to plant one tree for every goal he scored for his innovative initiative “Trees For Goals”. Currently, he plants 11 trees for every goal to represent his entire team. Over the last two years, Lesein has planted over 2000 trees including 700 trees at Karura Forest with the help of his football team at Ligi Ndogo.',
    ],
  },
  {
    name: 'Erina Wanzul',
    position: '2023 - 2024 Youth Board member',
    photo: '/images/erina.jpeg',
    paragraphs: [
      'Erina is a 17-year-old Malaysian student attending SMK Seksyen 9 Shah Alam Selangor. She is deeply committed to promoting economic growth and development through innovation in order to reduce negative externalities in STEM. She has been involved in F1 in Schools since three years, leading two F1 in Schools teams, Keris Racing and ECOLEST9, to victory in the Malaysia F1 in Schools National Finals in 2020 and 2021 as project manager and manufacturing engineer for both teams, and qualifying as a World Finalist in 2021 and 2022. Her team entered their team brand ECOLEST9 at the recent F1 in Schools World Finals, Silverstone, United Kingdom project. They managed to win two awards: the best pit display award and the best team identity award.',
    ],
  },
  {
    name: 'Kareena Shah',
    position: '2023 - 2024 Youth Board member',
    photo: '/images/kareena.webp',
    paragraphs: [
      'Kareena Shah is a 17-year-old innovator based in the U.S who is a student at The Knowledge Society. She started a World Water Crisis Awareness Campaign highlighting the water accessibility and safety crises. Her travels to Haryana, India enabled her to work hands-on in marginalized communities by testing for microbial contamination in drinking water. To globalize her vision, she created One Drop at A Time, a podcast which unravels the contributing factors to this acute crisis. She hopes to empower individuals to advocate for changes within their own communities to preserve our Earth’s most important natural resource.',
    ],
  },
  {
    name: 'Carlota Ferro',
    position: '2023 Youth Board member',
    photo: '/images/carlota.webp',
    paragraphs: [
      'Carlota is a 17-year-old student at Collège du Léman (CDL) International School, Switzerland who is originally from Lisbon but currently resides in Geneva. She has always been passionate about international issues, specifically inequality, discrimination and sustainability. She is currently the president of a student group at CDL which fundraises for WATU, a charity with various education projects in Tanzania. She coordinates the group’s efforts and organizes multiple fundraisers for WATU each year. She is also the president of the CDL Environmental Association, through which she leads students in creating projects that attempt to make her school more sustainable. In June of 2022, she attended the Villars Symposium, where she began working with other students, teachers, and Norwegian food activist Gunhild Stordalen to create an initiative to push for increased use of planetary health diets in schools. She plans to move to London next year to continue her studies in politics, international relations, and social sciences at university.',
    ],
  },
  {
    name: 'Matteo Markel',
    position: '2022 - 2023 Youth Board member',
    photo: '/images/matteo.webp',
    paragraphs: [
      'Matteo Markel is a 16 year old student at the International School of Zug and Luzern in Switzerland. From a young age, he was interested in science and solving environmental issues using technology. He is passionate about carbon reduction and the sustainable systems shaping the future. Matteo worked on the Aquaponics System at ISZL, a project where waste from a fish tank is recirculated to plants which grow and clean the water for the fish. In 2020, he won a grant from the IB and the change-maker association Ashoka. Currently, he is committed to helping ISZL achieve their goal of carbon neutrality by 2027. Looking ahead, Matteo plans to take science courses about ways to balance economic growth with positive social and environmental goals.',
    ],
  },
  {
    name: 'Georgia Scarr',
    position: '2022 Youth Board member',
    photo: '/images/georgia.jpeg',
    paragraphs: [
      'Georgia Scarr is a 17 year old IB student, born in London and raised in Chicago. An environmentalist and humanitarian at her core, Georgia is a passionate activist. She works in several environmental organizations, including being co-founder and co-executive director of the non-profit Eco Circle International, and a core team member of non-profit Illinois Youth Climate Movement. She plans to study Environmental Science at university, with hopes to travel the world in a van afterwards, engaging communities through science to make the world more sustainable. In her free time, Georgia enjoys hiking and camping.',
    ],
  },
  {
    name: 'Maria Šimůnková',
    position: '2022 Youth Board member',
    photo: '/images/maria.jpeg',
    paragraphs: [
      'Maria Šimůnková is an 18 year old student from the Czech Republic interested in Artificial Intelligence and education. At the age of 16, Maria developed a chatbot for General Practitioners. To learn more about AI, Maria completed an internship at the Czech Academy of Sciences, where she worked on a neural network program for biomedical image segmentation. Currently, Maria is leading an educational non-profit organization focused on educating young people on how to manage their finances.',
    ],
  },
  {
    name: 'Saoirse Exton',
    position: '2022 Youth Board member',
    photo: '/images/saoirse.jpeg',
    paragraphs: [
      "Saoirse Exton began her work as a climate activist at the age of 13 in Limerick, Ireland when she founded the local branch of the Fridays for Future movement in her city. Saoirse is a member of the C40 Global Youth and Mayors Forum, which creates an intergenerational conversation on the climate crisis among 14 youth activists from across the world and mayors of the world's mega-cities. She is currently serving her second term as Equality Officer of the Irish Second-Level Students' Union, which enables her to bring student voices to the forefront of decision-making in Ireland.",
    ],
  },
  {
    name: 'Vinisha Umashankar',
    position: '2022 Youth Board member',
    photo: '/images/vinisha.jpeg',
    paragraphs: [
      'Vinisha Umashankar is a 15 year old student, an innovator and a public speaker. She lives in Tiruvannamalai, Tamil Nadu, India. She started her innovation journey when she was 12 years old. Videos about her innovation, the Solar Ironing Cart, have been seen over 10 million times with 70,000+ comments. She focuses on innovations that help the less-privileged make their lives more comfortable. Her innovations have won national and international awards. She is the recipient of Earth Day Network "Rising Star" 2021 (USA), Children\'s Climate Prize 2020 (Sweden) and Dr. A.P.J. Abdul Kalam IGNITE Award 2019 (India).',
    ],
  },
];
export const OUR_PLANET_DATA = [
  {
    num: '',
    top: '34.1371%',
    left: '50.9988%',
    topBubble: '34.1371%',
    leftBubble: '0',
    rightBubble: '49.0012%',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: 'Atmosphere',
    image: '',
    textContent: [
      <>
        The word <span>atmosphere</span> comes from the combination of the Greek
        words atmos, meaning “vapor”, and sphaira, meaning “sphere”, and it
        describes{' '}
        <span>the outer layer of gases that surrounds our planet.</span>
      </>,
      <>
        It is made up of 78% nitrogen, 21% oxygen, 0.9% argon, and 0.1% other
        gases such as carbon dioxide (CO), water vapor, or methane (CH4), and it{' '}
        <span>
          extends from the Earth’s surface, outward into space, up to as far as
          10,000 km (6,214 miles) above
        </span>
        . (
        <a
          href="https://www.nationalgeographic.org/encyclopedia/atmosphere/"
          rel="noopener noreferrer"
          target="_blank"
        >
          National Geographic
        </a>
        ) The atmosphere helps to keep the temperatures of our planet within
        livable limits. Without it, the planet would be either too hot or too
        cold, and life would not be possible.
      </>,
    ],
  },
  {
    num: '',
    top: '68.9506%',
    left: '77.2101%',
    topBubble: 'initial',
    leftBubble: '0',
    rightBubble: '22.7899%',
    bottomBubble: '31.0494%',
    isTop: false,
    isMiddle: false,
    isBottom: true,
    isRight: true,
    isLeft: false,
    titleItem: 'Hydrosphere',
    image: '/images/hydrosphere.jpg',
    textContent: [
      <>
        The word <span>hydrosphere</span> comes from a combination of the Greek
        root hydro-, "water", and sphere, "globe, cosmos, or space", from the
        Greek sphaira, "globe or ball", and it refers to{' '}
        <span>the total amount of water,</span> both freshwater and saltwater,{' '}
        <span>present on Earth</span>, including water on, under, and above the
        surface of the planet.
      </>,
      <>
        Around{' '}
        <span>70% of the Earth’s surface is covered by liquid water</span>, in
        oceans, seas, lakes and rivers. However, water can also be found
        underground and floating in the air as water vapor, such as in clouds.
      </>,
      <>
        Water is essential for sustaining life on Earth, as it is a key element
        in the cellular processes of all living beings: liquid water helps cells
        transport and use substances, allows them to maintain their shape, and
        enables the chemical reactions necessary for their correct functioning.{' '}
        <span>
          There is no single organism in our world that could exist without
          water.
        </span>{' '}
        Additionally, water helps connect the planet’s spheres through the water
        cycle.
      </>,
    ],
  },
  {
    num: '',
    top: '75.689%',
    left: '50.1668%',
    topBubble: 'initial',
    leftBubble: '0',
    rightBubble: '49.8332%',
    bottomBubble: '24.311%',
    isTop: false,
    isMiddle: false,
    isBottom: true,
    isRight: true,
    isLeft: false,
    titleItem: 'Lithosphere',
    image: '/images/lithosphere.jpg',
    textContent: [
      <>
        The word <span>lithosphere</span> comes from the combination of the
        Greek words lithos, meaning "stone", and sphaira, meaning “globe, ball
        or sphere”. The lithosphere is the{' '}
        <span>rigid, outermost layer of the Earth</span>, from the peak of the
        highest mountain to an approximate depth of 100km, including all the
        abiotic (nonliving) elements found within (minerals, fossils, rocks,
        etc.).
      </>,
      <>
        The most important feature of the lithosphere is{' '}
        <span>tectonic activity</span>; that is, the interaction between
        tectonic plates - the huge slabs of rock that divide this layer of the
        Earth. Tectonic activity is responsible for the phenomena that shaped,
        and continues to shape, the Earth’s landscape: mountain formation,
        volcanoes, ocean trenches, earthquakes, etc., all of which have a great
        impact on the distribution of land masses and the connectivity of the
        oceans, and therefore, on our global ecosystem.
      </>,
      <>Photo source: TheConversation/Shutterstock</>,
    ],
  },
  {
    num: '',
    top: '53.574%',
    left: '37.874%',
    topBubble: 'initial',
    leftBubble: '37.874%',
    rightBubble: '0',
    bottomBubble: '46.426%',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'Biosphere',
    image: '/images/biosphere.jpg',
    textContent: [
      <>
        The word <span>biosphere</span> comes from the Greek word bios, meaning
        "life", and sphaira, or "sphere". As described in the introduction, the
        biosphere refers to the Earth’s global ecosystem; that is,{' '}
        <span>
          the collection of all ecosystems that exist in our world, including
          both the living and nonliving elements present in them.
        </span>
      </>,
      <>
        As life can be found in the ground, in the water and in the air, the
        biosphere also includes the other three spheres to a certain extent.
        This thin layer of the planet where all four spheres connect is also
        known as the <span>critical zone</span>: the surface of the planet that
        extends from the top of the vegetation canopy to the underground area
        where we can find aquifers and fresh groundwater. (
        <a
          href="https://czo-archive.criticalzone.org/national/research/the-critical-zone-1national/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Critical Zone Observatories
        </a>
        ) The zone is considered critical because{' '}
        <span>
          it is where activities essential to most terrestrial life take place
        </span>
        .
      </>,
      <>Photo source: Normaals /123RF</>,
    ],
  },
];
export const CARBON_CYCLE_DATA = [
  {
    num: '1',
    top: '6.61564%',
    left: '34.7059%',
    topBubble: '6.61564%',
    leftBubble: '34.7059%',
    rightBubble: '0px',
    bottomBubble: 'initial',
    isTop: true,
    isMiddle: false,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'The Carbon Cycle',
    image: '',
    textContent: [
      <>
        You might have heard that the amount of carbon dioxide in the atmosphere
        is increasing. However,{' '}
        <span>the amount of carbon present on our planet never changes</span>.
        This is because carbon atoms are continually being{' '}
        <span>recycled and reused</span> in the natural world - they move from
        one sphere to the next.
      </>,
      <>
        The diagram below describes the Carbon Cycle. The blue arrows indicate{' '}
        <span>how carbon atoms can move</span> from one place to the next. These
        processes are sometimes slow and sometimes fast.
      </>,
      <>
        The <span>Slow Carbon Cycle</span> occurs mainly through nonliving
        organisms, and movements are triggered mostly by chemical reactions and
        tectonic activity. The <span>Fast Carbon Cycle</span> is the movement of
        the atom that occurs mostly among living organisms. To read more about
        this, you can head to the{' '}
        <a
          href="https://www.earthobservatory.nasa.gov/features/CarbonCycle"
          rel="noopener noreferrer"
          target="_blank"
        >
          NASA Earth Observatory website
        </a>
        .
      </>,
      <>
        Click on the buttons in the diagram to better understand the carbon
        cycle.
      </>,
      <>
        Photo source: OpenStax College, Biology. Modification of work by John M.
        Evans and Howard Perlman, USGS
      </>,
    ],
  },
  {
    num: '2',
    top: '19.6601%',
    left: '57.0431%',
    topBubble: '19.6601%',
    leftBubble: '0px',
    rightBubble: '42.9569%',
    bottomBubble: 'initial',
    isTop: true,
    isMiddle: false,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: 'Carbon dioxide in the atmosphere',
    image: '',
    textContent: [
      <>
        Carbon can be found in the atmosphere in the form of{' '}
        <span>carbon dioxide (CO2)</span> molecules or{' '}
        <span>methane (CH4)</span>. Carbon dioxide and methane are greenhouse
        gases (GHG) <span>that trap heat in the atmosphere</span>; without these
        and other gases, the Earth would be too cold for life to exist.
      </>,
    ],
  },
  {
    num: '4',
    top: '45.4521%',
    left: '39.7846%',
    topBubble: '45.4521%',
    leftBubble: '39.7846%',
    rightBubble: '0px',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'Photosynthesis',
    image: '',
    textContent: [
      <>
        From the atmosphere, carbon dioxide is <span>absorbed by plants</span>{' '}
        to produce their own energy through a process called{' '}
        <span>photosynthesis</span>, in which carbon dioxide, water, and light{' '}
        <span>produce organic molecules and oxygen</span>. This is why plants
        are absolutely essential to human life.
      </>,
    ],
  },
  {
    num: '5',
    top: '47.3949%',
    left: '58.0081%',
    topBubble: '47.3949%',
    leftBubble: '0px',
    rightBubble: '41.9919%',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: 'Food chain',
    image: '',
    textContent: [
      <>
        Carbon is thus present in plants, and also in living organisms - in
        fact, it is a vital element because it is one of the components of
        carbohydrates, fats, and proteins.{' '}
        <span>The human body consists of about 18% carbon.</span> Carbon moves
        across organisms <span>through the food chain</span> (when animals eat
        plants, or predators eat their prey).
      </>,
    ],
  },
  {
    num: '6',
    top: '86.3932%',
    left: '45.085%',
    topBubble: 'initial',
    leftBubble: '45.085%',
    rightBubble: '0px',
    bottomBubble: '13.6068%',
    isTop: false,
    isMiddle: false,
    isBottom: true,
    isRight: false,
    isLeft: true,
    titleItem: 'Microbial respiration and decomposition',
    image: '',
    textContent: [
      <>
        When animals and plants die, they are decomposed by bacteria. During
        this <span>decomposition process</span>, Carbon is released into the
        atmosphere or into the water as CO2. Sometimes, the Carbon ends up in
        the soil.
      </>,
    ],
  },
  {
    num: '7',
    top: '87.904%',
    left: '9.5642%',
    topBubble: 'initial',
    leftBubble: '9.5642%',
    rightBubble: '0px',
    bottomBubble: '12.096%',
    isTop: false,
    isMiddle: false,
    isBottom: true,
    isRight: false,
    isLeft: true,
    titleItem: 'Fossil Carbon',
    image: '',
    textContent: [
      <>
        When dead plant or animal material is buried deeper and deeper, and
        therefore exposed to pressure and heat, the organic material (which
        contains Carbon) may not fully decompose and become fossilized instead.
        Through <span>fossilization</span>, coal, natural gas, or oil are
        formed; however, <span>this process can take millions of years</span>,
        and that is why fossil fuels are considered non-renewable sources of
        energy.
      </>,
      <>
        The Carbon which is trapped in the geosphere can leave in various
        manners - during a volcanic eruption, during a geological process called
        subduction, or when humans dig deep to extract fossil fuels.
      </>,
    ],
  },
  {
    num: '8',
    top: '38.9316%',
    left: '8.36556%',
    topBubble: '38.9316%',
    leftBubble: '8.36556%',
    rightBubble: '0px',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'Weathering of terrestrial rocks',
    image: '',
    textContent: [
      <>
        In addition, Carbon also moves from the atmosphere into the lithosphere{' '}
        <span>through rain</span>. When Carbon is combined with water in the
        atmosphere, it creates <span>carbonic acid.</span> Rain causes{' '}
        <span>this acid to dissolve rocks,</span> which then release substances
        like calcium, magnesium, or sodium that are transported by rivers into
        the ocean, where they become <span>calcium carbonate</span> (calcium
        ions combined with bicarbonate ions).
      </>,
    ],
  },
  {
    num: '3',
    top: '33.312%',
    left: '81.6263%',
    topBubble: '33.312%',
    leftBubble: '0px',
    rightBubble: '18.3737%',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: '',
    image: '',
    textContent: [
      <>
        <span>Carbon is released into the atmosphere as carbon dioxide</span> in
        several different ways: through the respiration of humans and animals,
        during a volcanic eruption or through other human activities such as the
        burning of fossil fuels or deforestation.
      </>,
      <>
        Carbon dioxide{' '}
        <span>
          is removed from the atmosphere primarily through photosynthesis
        </span>
        , entering the terrestrial and oceanic biospheres. Carbon dioxide{' '}
        <span>is also removed by other processes</span> - for example, it can be
        precipitated and dissolved by rain, thereby ending up on land or in the
        waters.
      </>,
    ],
  },
];
export const ENVIRONMENTAL_ISSUES = [
  {
    num: '',
    top: '50.633%',
    left: '40.3614%',
    topBubble: 'initial',
    leftBubble: '40.3614%',
    rightBubble: '0px',
    bottomBubble: '49.367%',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'How soil erosion and deforestation cause pollution',
    image: '',
    textContent: [
      <>
        Erosion and deforestation <span>weaken soil structure,</span> which
        increases the sweeping of land into waterways. This helps{' '}
        <span>
          pollution and sedimentation make their way into streams and rivers.
        </span>
      </>,
      <>
        Land use linked to agriculture is <span>one of the main polluters</span>{' '}
        of land, air, and water. Farmers use chemical fertilizers and animal
        manure to provide nutrients (nitrogen and phosphorus) to their crops;
        however, all the <span>leftover chemicals</span> that are not absorbed
        by the plants <span>make their way into the air and water streams</span>
        , damaging ecosystems. Similarly, chemical pesticides used by farmers
        can contaminate our air and water, ending up in our food.
      </>,
    ],
  },
  {
    num: '',
    top: '44.2486%',
    left: '96.0544%',
    topBubble: '44.2486%',
    leftBubble: '0px',
    rightBubble: '3.94558%',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: 'How GHG emissions cause Ocean Acidification',
    image: '',
    textContent: [
      <>
        A large percentage of the <span>carbon dioxide</span> (CO2) present in
        our atmosphere is actually <span>absorbed by the oceans</span>, where it
        interacts with seawater to <span>form carbonic acid</span>. The increase
        in greenhouse gas emissions caused by human activity has led the oceans
        to absorb even higher amounts of atmospheric carbon dioxide (CO2). The
        increased presence of CO2 in the oceans leads to{' '}
        <span>a decrease in ocean pH,</span> which makes the ocean more acidic.{' '}
        <span>
          Ocean acidification reduces the availability of calcium minerals
        </span>{' '}
        needed by shellfish and corals for skeleton building and repair (
        <a
          href="https://www.iucn.org/resources/issues-briefs/ocean-acidification"
          rel="noopener noreferrer"
          target="_blank"
        >
          IUCN
        </a>
        ).
      </>,
    ],
  },
  {
    num: '',
    top: '94.9961%',
    left: '65.034%',
    topBubble: 'initial',
    leftBubble: '0px',
    rightBubble: '34.966%',
    bottomBubble: '5.00389%',
    isTop: false,
    isMiddle: false,
    isBottom: true,
    isRight: true,
    isLeft: false,
    titleItem: 'How global warming and climate change lead to water scarcity',
    image: '',
    textContent: [
      <>
        The{' '}
        <span>
          higher temperatures and increasingly unpredictable weather patterns
        </span>{' '}
        caused by global warming and climate change across the planet have a
        profound negative impact on freshwater resources. The new climatic
        conditions have greatly <span>disrupted the global water cycle</span>,
        affecting the availability and distribution of rainfall, snowmelt, river
        flows and groundwater, and leading to increasingly stronger droughts and
        floods that decrease water quality. (
        <a
          href="https://www.weforum.org/agenda/2020/09/climate-change-impact-water-security-risk/"
          rel="noopener noreferrer"
          target="_blank"
        >
          World Economic Forum
        </a>
        )
      </>,
    ],
  },
  {
    num: '',
    top: '21.986%',
    left: '4.7619%',
    topBubble: '21.986%',
    leftBubble: '4.7619%',
    rightBubble: '0px',
    bottomBubble: 'initial',
    isTop: true,
    isMiddle: false,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem:
      'How deforestation and soil erosion lead to habitat and biodiversity loss',
    image: '',
    textContent: [
      <>
        The <span>clear-cutting of trees</span> associated with the conversion
        of wild terrestrial environments to agricultural land, along with urban
        growth and building construction, negatively impacts biodiversity by{' '}
        <span>destroying or fragmenting natural habitats</span>, which reduces
        or eliminates food resources and living space for species. In this case,{' '}
        <span>if species are not able to migrate, they will likely die.</span>
      </>,
    ],
  },
  {
    num: '',
    top: '44.3047%',
    left: '66.8537%',
    topBubble: '44.3047%',
    leftBubble: '0px',
    rightBubble: '33.1463%',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: true,
    isLeft: false,
    titleItem: 'How pollution emits GHG emissions',
    image: '',
    textContent: [
      <>
        <span>Trash</span> is an important but frequently forgotten{' '}
        <span>source of greenhouse gases (GHG)</span>. When trash is not
        recycled, it ends up piling up in gigantic mountains of garbage in our
        landfills. When all the organic waste (food, paper, wood scraps, etc.)
        begins to decompose in conditions where there is very little oxygen due
        to the surrounding inorganic trash, it releases into the atmosphere{' '}
        <span>large amounts of methane (CH4)</span>, a greenhouse gas far more
        potent than carbon dioxide (CO2) and a major contributor to global
        warming. Sometimes trash is disposed of through{' '}
        <span>incineration </span>(burning), which also releases greenhouse
        gases into the atmosphere along with other toxic pollutants.
      </>,
    ],
  },
  {
    num: '',
    top: '44.5834%',
    left: '30.4911%',
    topBubble: '44.5834%',
    leftBubble: '30.4911%',
    rightBubble: '0px',
    bottomBubble: 'initial',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'How land use causes soil erosion',
    image: '',
    textContent: [
      <>
        <span>Soil erosion</span> refers to the process by which{' '}
        <span>soil is carried away</span> by wind, water, or mass movement. The
        way we transform wild habitats such as forests, mangroves or grasslands
        for farming and food production, for infrastructure expansion, or for
        raw materials extraction has a profound negative impact on our world’s
        natural land resources. This use of land has caused a drastic{' '}
        <span>
          global decline in soil quality and the widespread loss of fertile
          land.
        </span>{' '}
        The massive clearing of trees - also known as <span>deforestation</span>{' '}
        - is one of the main causes of soil erosion.
      </>,
    ],
  },
  {
    num: '',
    top: '59.4136%',
    left: '39.4877%',
    topBubble: 'initial',
    leftBubble: '39.4877%',
    rightBubble: '0px',
    bottomBubble: '40.5864%',
    isTop: false,
    isMiddle: true,
    isBottom: false,
    isRight: false,
    isLeft: true,
    titleItem: 'How pollution is linked to habitat and biodiversity loss',
    image: '',
    textContent: [
      <>
        <span>All forms of pollution pose a threat to biodiversity.</span> Some
        are quite evident, such as the death of thousands of animals every year
        due to <span>plastic pollution</span> after eating or getting entangled
        in plastic waste. Other ways in which pollution harms biodiversity are
        not as obvious.
      </>,
      <>
        An example is <span>nutrient overload</span> in water ecosystems:
        pollution from the use of agricultural fertilizers or from the discharge
        of wastewater into rivers, streams, and lakes can lead to an overload of
        nutrients in these water bodies, which leads to{' '}
        <span>an overgrowth of algae and other aquatic plants.</span> As a
        consequence, plants start taking up all the oxygen present in the water,
        making it impossible for other species to survive. This process is
        called <span>eutrophication</span> and creates what we call “dead
        zones”. You can read more about it{' '}
        <a
          href="https://www.nationalgeographic.org/encyclopedia/dead-zone/"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </>,
    ],
  },
];

export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
export const OKTA_DOMAIN = process.env.NEXT_PUBLIC_OKTA_DOMAIN;
export const CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID;

export const POSTS_PER_PAGE_IN_REQUEST = 100;
export const DEV_MODE = true;
export const NEXT_PUBLIC_STORYBLOK_TOKEN =
  process.env.NEXT_PUBLIC_STORYBLOK_TOKEN;
